<div class="service-banner-section">
    <div class="bg-white pt-5">
    <div class="container p-0">
      <div class="row">
        <div class="col-sm-3">
          <ul class="listmenuli p-0">
            <li class="active-li"><a href="#section1">Section 1</a></li>
            <li><a href="#section2" (click)="onStop($event)">Section 2</a></li>
            <li><a href="#section3">Section 3</a></li>
            <li><a href="#section4">Section 4</a></li>
            <li><a href="#section5">Section 5</a></li>
          </ul>
        </div>
        <div class="col-sm-9">
          <div id="section1">
            <h1>Section 1</h1>
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p><h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p><h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p><h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
          </div>
          <div id="section2">
            <h1>Section 2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
          </div>
          <div id="section3">
            <h1>Section 3</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
          </div>
          <div id="section4">
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p><h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p><h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p><h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
            <h1>Section 4-1</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
          </div>
          <div id="section5">
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
            <h1>Section 4-2</h1>
            <p>Try to scroll this section and look at the navigation list while scrolling!</p>
          </div>
        </div>
      </div>
    </div>
</div>
</div>