<!-- <header>
    <nav id="main-navbar" class="navbar navbar-default navbar-fixed-top">
        <div class="container navbar-container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
					<span class="sr-only">Toggle navigation</span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
                <a class="navbar-brand" href="#">Online Tax</a>
            </div>
            <div id="navbar" class="navbar-collapse collapse">
                <ul class="nav navbar-nav navbar-right">
                    <li class="dropdown"><a href="#">Staring a New Business</a>
                        <ul class="dropdown-content">
                            <li>dropdown</li>
                            <li>dropdown</li>
                            <li>dropdown</li>
                            <li>dropdown</li>
                        </ul>
                    </li>
                    <li><a href="#about">Income Tax Filing</a></li>
                    <li><a href="#portfolio">GST Registration</a></li>
                    <li><a href="#contact">Others</a></li>

                </ul>
            </div>
            <div class="top-social">
                <ul id="top-social-menu">
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                    <li><a href="#"><i class="fa fa-phone"></i></a></li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<section class="full-width-img">

</section> -->

<!-- Main Header-->
<header class="main-header">
  <!-- Header Top -->
  <div class="header-top">
    <div class="auto-container">
      <div class="inner-container clearfix">
        <!-- Top Left -->
        <div class="top-left">
          <!-- Info List -->
          <ul class="info-list">
            <li>
              <a href="mailto:connect2fileyouritr@gmail.com"
                ><span class="icon icofont-envelope"></span>
                connect2fileyouritr@gmail.com</a
              >
            </li>
            <li>
              <a href="tel:+91 -8688062159"
                ><span class="icon icofont-phone"></span> +91 -8688062159</a
              >
            </li>
            <!-- <li><a href="contact.html"><span class="icon icofont-clock-time"></span> Mon - Sat: 8.00
                                    - 17.00, Sunday Closed</a></li> -->
          </ul>
        </div>

        <!-- Top Right -->
        <div class="top-right pull-right">
          <!-- Social Box -->
          <ul class="social-box">
            <li class="share">Our Social</li>
            <li><a href="https://twitter.com/" class="icofont-twitter"></a></li>
            <li>
              <a href="http://facebook.com/" class="icofont-facebook"></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/"
                class="icofont-instagram"
              ></a>
            </li>
            <!-- <li><a href="https://rss.com/" class="icofont-rss"></a></li>
                            <li><a href="https://www.youtube.com/" class="icofont-play-alt-1"></a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Header Upper -->
  <div class="header-upper">
    <div class="auto-container">
      <div class="inner-container clearfix">
        <div class="pull-left logo-box">
          <div class="logo">
            <a href="index.html"
              ><img src="../../../assets/images/logo.png" alt="" title=""
            /></a>
          </div>
        </div>

        <div class="nav-outer pull-right clearfix">
          <!-- Main Menu -->
          <nav class="main-menu navbar-expand-md">
            <div class="navbar-header">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>

            <div
              class="navbar-collapse show collapse clearfix"
              id="navbarSupportedContent"
            >
              <ul class="navigation clearfix">
                <li class="current">
                  <a [routerLink]="['/home']" routerLinkActive="true">Home</a>
                </li>
                <li class="dropdown">
                  <a (click)="onUpdateServices('Startup')">Start Up</a>
                  <ul>
                    <li>
                      <a (click)="onUpdateServices('proprietorShip')"
                        >Proprietor ship</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('partnerShip')"
                        >Partnership</a
                      >
                    </li>
                    <!-- <li>
                      <a (click)="onUpdateServices('AssociationOfWorkers')"
                        >Society or Association of persons</a
                      >
                    </li> -->
                    <li>
                      <a (click)="onUpdateServices('OnePersonCompany')"
                        >One person Company</a
                      >
                    </li>
                    <li>
                      <a
                        (click)="
                          onUpdateServices('LimitedLiabilityPartnership')
                        "
                        >Limited liability Partnership</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('privateLimitedCompany')"
                        >Private limited company</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('Section8Company')"
                        >Section 8 Company (NGO)</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="dropdown">
                  <a (click)="onUpdateServices('IncomeTaxFiling')"
                    >Income Tax</a
                  >
                  <ul>
                    <li>
                      <a (click)="onUpdateServices('Individual')">Individual</a>
                    </li>
                    <li>
                      <a (click)="onUpdateServices('Bussiness')">Bussiness</a>
                    </li>
                  </ul>
                </li>
                <li class="dropdown">
                  <a (click)="onUpdateServices('GSTServices')">GST</a>
                  <ul>
                    <li>
                      <a (click)="onUpdateServices('GSTRegistration')"
                        >GST Registration</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('GSTFiling')">GST Filing</a>
                    </li>
                    <li>
                      <a (click)="onUpdateServices('GSTAnnualReturn')"
                        >GST Annual Return</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="dropdown">
                  <a (click)="onUpdateServices('otherServices')"
                    >Other Services</a
                  >
                  <ul>
                    <li>
                      <a (click)="onUpdateServices('MSMERegistration')"
                        >MSME Registration</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('IECRegistration')"
                        >IEC Registration</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('digitalSignature')"
                        >Digital Signature</a
                      >
                    </li>
                    <li>
                      <a (click)="onUpdateServices('PANCard')"
                        >PAN Card</a
                      >
                    </li>
                  </ul>
                </li>
                <li><a href="javascript:void(0)" (click)="onRouterNavigate('/about')">About</a></li>

                <li><a href="javascript:void(0)" (click)="onRouterNavigate('/contact')">Contact</a></li>
              </ul>
            </div>
          </nav>
        </div>

        <!-- Outer Box -->
        <div class="outer-box">
          <!-- Mobile Navigation Toggler -->
          <div class="mobile-nav-toggler">
            <span class="icon ti-menu"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Header Upper-->

  <!-- Mobile Menu  -->
  <div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><span class="icon lnr lnr-cross"></span></div>

    <nav class="menu-box">
      <div class="nav-logo">
        <a href="../index.html"
          ><img src="../../../assets/images/logo.png" alt="" title=""
        /></a>
      </div>
      <div class="menu-outer">
        <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
      </div>
    </nav>
  </div>
  <!-- End Mobile Menu -->
</header>
<!--End Main Header -->
