<div class="page-wrapper terms-banner-section">
    <div class="bg-card">
        <div class="container">
         <h2>TERMS AND CONDITIONS </h2>
        </div>
    </div>
    <div class="container terms-para">
        <p class="pt-5">This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
        </p>
        <p>
            This document is published in accordance with the provisions of Rule 3 (1) (a) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal data or Information) Rules, 2011 framed under the (Indian) Information Technology Act, 2000 (as amended from time to time)  that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of www.FILE YOUR ITR.in Platform.
        </p>
        
        <!-- <p>
            The online platform <a href="#">–www.taxfiling.in,</a> is owned and operated by <b>Tax Filing PRIVATE LIMITED,</b> a private limited company incorporated under the Companies Act, 2013 with its registered office at 203, Fortune Heights C-94, Subhash Marg, Jaipur, Rajasthan-302001 ("FILE YOUR ITR", "We", "Our". "Us"). The Website its sub-domains and other sites/ apps/ other channels including any mobile applications maintained by FILE YOUR ITR to enable the Services shall be collectively referred to hereinafter as the ("Platform"). The products and services provided by FILE YOUR ITR through the Platform shall be referred as ("Services"). 
        </p> -->
        
        <p>
            The words "You" and "Your" refer to the person(s) who use and avail the Services provided by FILE YOUR ITR through the Platform and shall include both singular and plural. The Services provided herein is only for the Indian citizens and tax resident of India.   
        </p>
        
        <h2>
            1. ACCEPTANCE TO THE TERMS OF USE 
        </h2>
        
        <p >This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
        </p>
        <p>
            This document is published in accordance with the provisions of Rule 3 (1) (a) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal data or Information) Rules, 2011 framed under the (Indian) Information Technology Act, 2000 (as amended from time to time)  that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of www.FILE YOUR ITR.in Platform.
        </p>
<!--         
        <p>
            The online platform –www.file your itr.in, is owned and operated by WINIIN TAXSCOPE PRIVATE LIMITED, a private limited company incorporated under the Companies Act, 2013 with its registered office at 203, Fortune Heights C-94, Subhash Marg, Jaipur, Rajasthan-302001 ("FILE YOUR ITR", "We", "Our". "Us"). The Website its sub-domains and other sites/ apps/ other channels including any mobile applications maintained by FILE YOUR ITR to enable the Services shall be collectively referred to hereinafter as the ("Platform"). The products and services provided by FILE YOUR ITR through the Platform shall be referred as ("Services"). 
        </p> -->
        <h2>2. ELIGIBILITY</h2>
        <p>Use of the Platform and/or the Services, either by registration or by any other means, is available only to persons, who are citizens of India or Non-Resident Indians, who are 18 years of age and above and persons who can enter into a legally binding contract, and/or are not barred by any law for the time being in force.</p>
      
        <h2>3. DESCRIPTION OF SERVICE PROVIDER</h2>
       
        <p>Form and nature of the Services which FILE YOUR ITR provides may change from time to time without prior notice to You. FILE YOUR ITR may stop (permanently or temporarily) providing the Services (or any features within the Services) to You or to users generally at FILE YOUR ITR's sole discretion, without prior notice to You. You may stop using the Services at any time. Normally, You do not need to specifically inform FILE YOUR ITR when You stop using the Services. You acknowledge and agree that if FILE YOUR ITR disables access to Your account, you may be prevented from accessing the Services and/or the Platform, Your account details or any files or other content which is contained in Your account.</p>
        
        <p>You agree to allow Us to add You as Our client on the Income Tax Department web services to submit ITR, retrieve ITR-V, refund status, 26AS, etc as per the Electronic Furnishing of Return of Income Scheme, 2007 including any subsequent amendments, notifications or similar schemes, regulations, rules issued from time to time.</p>
       
        <p>You acknowledge and agree that while FILE YOUR ITR may not currently have set a fixed upper limit on the number of transmissions You may send or receive through the Services or on the amount of storage space used for the provision of any Service, such fixed upper limits may be set by FILE YOUR ITR at any time, at FILE YOUR ITR’s discretion.</p>
       
        <h2>4. USE OF THE SERVICES BY YOU</h2>
       
        <p>While preparing Your tax return / filing Your tax return or to provide advice about tax matters, You will need to provide information about Your income, investments, credits, dependents, etc. Collectively, this information is known as (“Tax Return Information”). In order to use FILE YOUR ITR Services, You will need to provide Your Tax Return Information. You agree that any Tax Return Information You give to FILE YOUR ITR will always be accurate, correct and up to date and FILE YOUR ITR shall not in any way be liable for any claims or disputes, which arises due to any wrong Tax Return Information provided by You and/or which are not attributable to FILE YOUR ITR.</p>
       
        <p>We will prepare the return as per the Tax Return Information given by You. FILE YOUR ITR is only medium/platform between You and income tax department for filing the income tax return and other related services. You will be solely responsible for any action taken against You by the income tax department.</p>
       
        <p>You will not access any of the Services by any means other than through the interface that is provided by FILE YOUR ITR, unless You have been specifically allowed to do so in a separate agreement with FILE YOUR ITR. You specifically agree not to access any of the Services through any automated means (including use of scripts or web crawlers) and shall ensure that You comply with the instructions set out in any robots.txt file present on the Services. You agree that You will not engage in any activity that interferes with or disrupts the Services. Unless You have been specifically permitted to do so in a separate agreement with FILE YOUR ITR, You agree that You will not reproduce, duplicate, copy, sell, trade or resell the Services for any purpose.</p>
    </div>
</div>
<!-- <a [routerLink]="['/privacypolicy']" style="margin-top: 20px;">Privacy Policy</a> -->