import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandcond',
  templateUrl: './termsandcond.component.html',
  styleUrls: ['./termsandcond.component.scss']
})
export class TermsandcondComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
