<div class="page-wrapper contact-banner-section">
 	
    <!-- Main Header-->
    <header class="main-header style-three">
    	
        
		<!-- Mobile Menu  -->
        <div class="mobile-menu">
            <div class="menu-backdrop"></div>
            <div class="close-btn"><span class="icon lnr lnr-cross"></span></div>
            
            <nav class="menu-box">
                <div class="nav-logo"><a href="index.html"><img src="images/logo.png" alt="" title=""></a></div>
                <div class="menu-outer"><!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--></div>
            </nav>
        </div><!-- End Mobile Menu -->
		
    </header>
    <!-- End Main Header -->
	
	<!-- Map Section -->
	<div class="map-section">
		<div class="contact-map-area">
			<iframe class="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3211.5409287974803!2d78.35920147171329!3d17.44675154687475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb935edaf15937%3A0x267296f5e3fd660e!2sAwfis%20Srestha%20Marvel%20Gachibowli!5e1!3m2!1sen!2sin!4v1714217979832!5m2!1sen!2sin" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
		</div>
	</div>
	<!-- End Map Section -->
	
	<!-- Contact Page Section -->
	<div class="contact-page-section">
		<div class="auto-container">
			<!-- Contact Info Boxed -->
			<div class="contact-info-boxed">
				<div class="row clearfix">
					
					<!-- Column -->
					<div class="column col-lg-6 col-md-6 col-sm-12">
						<h2>Gachibowli, Hyderabad</h2>
						<!-- <div class="text">Gachibowli, Hyderabad</div> -->
						<div class="email">Email: <a href="sivandu55@gmail.com">sivandu55@gmail.com</a></div>
					</div>
					
					<!-- Column -->
					<div class="column col-lg-6 col-md-6 col-sm-12">
						<div class="call">Call directly:<br><a href="#">+91 809-902-9010</a></div>
						<ul class="location-list">
							<!-- <li><span>Brand Office:</span>Gachibowli, Hyderabad</li> -->
							<li><span>Work Hours:</span>24/7 </li>
						</ul>
					</div>
					
				</div>
			</div>
			
			<!-- Form Boxed -->
			<div class="form-boxed">
				<div class="sec-title centered">
					<div class="title">contact us</div>
					<h2>We Always Here <span>To Help You</span></h2>
				</div>
				
				<div class="boxed-inner">
					<!-- Contact Form -->
					<div class="contact-form">
						<!-- Contact Form -->
						<form method="post" action="https://htmlmail.hasthemes.com/shohel/consultix/mail.php" id="contact-form">
							<div class="row clearfix">
								<div class="col-lg-4 col-md-6 col-sm-12 form-group">
									<input type="text" name="name" placeholder="Name *" required>
								</div>

								<div class="col-lg-4 col-md-6 col-sm-12 form-group">
									<input type="email" name="email" placeholder="Emaill Address *" required>
								</div>

								<div class="col-lg-4 col-md-12 col-sm-12 form-group">
									<input type="text" name="subject" placeholder="Subject (Optional)" required>
								</div>

								<div class="col-lg-12 col-md-12 col-sm-12 form-group">
									<textarea name="message" placeholder="Message"></textarea>
								</div>

								<div class="col-lg-12 col-md-12 col-sm-12 text-center form-group">
									<button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="txt">Send Message</span></button>
								</div>

							</div>
						</form>
						<p class="form-messege"></p>

					</div>
					<!--End Contact Form -->
				</div>
				
			</div>
			
		</div>
	</div>
	<!-- End Blog Detail Section -->
	
	
</div>
<!--End pagewrapper-->

<!-- End Header Search -->