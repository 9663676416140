<!-- <div class="notice-section-grey notice-section-sm border-top">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-8 col-12">
                <div class="mt-5">
                    <h6>Looking for Professional Online Accounting Services?</h6>

                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-12">
                <div class="mt-10 right-holder-md"><a href="https://www.myaccountsconsultant.com/contact-us/" class="primary-button button-sm mt-15-xs">Contact Now</a></div>
            </div>
        </div>
    </div>
</div>


<footer class="site-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-5">
                <h6>About Us</h6>
                <p style="text-align: justify;">..... is a leading provider of Online Accounting Services have an uncanny ability to increase business income, riches, and achievement. We have revealed shrouded resources for the virtual bookkeeping services, accounting
                    services, payroll services.</p>

            </div>



            <div class="col-xs-6 col-md-3">
                <h6>Quick Links</h6>
                <ul class="footer-links">
                    <li><a href="">About Us</a></li>
                    <li><a href="">Contact Us</a></li>
                    <li><a href="">Services</a></li>
                    <li><a href="">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-xs-6 col-md-4">
                <h6>Get in Touch</h6>
                <p>
                   D.No:3-456, Plot no:12, Near cyber towers, Hyderabad
                </p>
                <ul class="connect-links ">
                    <li><a href=""><span>Phone:</span>+91 8099029010</a></li>
                    <li><a href=""><span>Email:</span> sivandu@gmail.com</a></li>
                </ul>
            </div>
        </div>
        <hr>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <p class="copyright-text">Copyright &copy; 2017 All Rights Reserved by
                    <a href="#">...</a>.
                </p>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">
                    <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                    <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
                    <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</footer> -->
<footer class="main-footer">
    <div class="auto-container">
        <!--Widgets Section-->
        <div class="widgets-section">
            <div class="row clearfix">

                <!-- Column -->
                <div class="big-column col-lg-6 col-md-12 col-sm-6 col-12">
                    <div class="row clearfix">

                        <!-- Footer Column -->
                        <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget logo-widget">
                                <div class="logo">
                                    <a href="index.html"><img src="../../../assets/../../../assets/images/logo.png" alt="" /></a>
                                </div>
                                <div class="call">
                                    Call us directly
                                    <a class="phone" href="tel:+91-8688062159">+91-8688062159</a>
                                    <a class="email" href="mailto:connect2fileyouritr@gmail.com">connect2fileyouritr@gmail.com</a>
                                </div>
                            </div>
                        </div>

                        <!-- Footer Column -->
                        <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget links-widget">
                                <h5>Company</h5>
                                <ul class="list-link">
                                    <li><a href="javascript:void(0)" (click)="onRouterNavigate('/about')">About Us</a></li>
                                    <li><a href="javascript:void(0)" (click)="onRouterNavigate('/contact')">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Column -->
                <div class="big-column col-lg-6 col-md-12 col-sm-6 col-12">
                    <div class="row clearfix">

                        <!-- Footer Column -->
                        <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget links-widget">
                                <h5>Quick Links</h5>
                                <ul class="list-link">
                                    <li><a href="javascript:void(0)" (click)="onRouterNavigate('/termsconditions')">Terms and conditions</a></li>
                                </ul>
                            </div>
                        </div>

                        <!-- Footer Column -->
                        <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget newsletter-widget">
                                <h5>Our Newsletter</h5>
                                <div class="text">Subscribe to our newsletter and we will inform you about
                                    latest updates and offers</div>
                                <!-- Newsletter Form -->
                                <div class="newsletter-form">
                                    <form method="post"
                                        action="">
                                        <div class="form-group">
                                            <input type="email" name="email" value=""
                                                placeholder="Email Address..." required>
                                            <button type="submit"
                                                class="theme-btn icofont-arrow-right"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!-- Footer Bottom -->
        <!-- <div class="footer-bottom">
            <div class="auto-container">
                <div class="bottom-inner">
                    <div class="row clearfix">

                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <div class="copyright">© 2021 <strong></strong> Made with <i
                                    class="icon-heart text-danger" aria-hidden="true"></i> by <a
                                    href="https://hasthemes.com/" target="_blank">HasThemes</a></div>
                        </div>

                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <ul class="social-nav">
                                <li><a href="https://twitter.com/" class="icofont-twitter"></a></li>
                                <li><a href="http://facebook.com/" class="icofont-facebook"></a></li>
                                <li><a href="https://www.instagram.com/" class="icofont-instagram"></a></li>
                                <li><a href="https://rss.com/" class="icofont-rss"></a></li>
                                <li><a href="https://www.youtube.com/" class="icofont-play-alt-1"></a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->

    </div>
</footer>