<div class="page-wrapper">
	<!-- Service Banner Section -->

    <div class="service-banner-section" data-bg-image="assets/images/background/service-bg.jpg" *ngIf="services?.hasSections">
		<div class="bg-card">
		<div class="container row">
			<div class="card_text">
			<h2>{{services?.title}}</h2>
			<p>{{services?.description}}</p>
		</div>
			  <div class="col-md-4 mt-5 mb-5 pt-5" *ngFor="let sections of services?.sections || []">
				  <div class="text-center bg-white product-card-design">
					<img src="https://assets1.cleartax-cdn.com/cleartax/images/1643883315_icon11c.png" alt="Person" class="">
					<div class="pb-3 card-service-body">
						<h3>{{sections?.title}}</h3>
						<p>{{sections.description}}</p>
					</div>
					<div class="card-button">
						<a href="javascript:void(0)" (click)="onUpdateServices(sections.title)">See More</a>
						</div>
				  </div>
			  </div>
		</div>
		</div>
	</div>
	<!-- End Service Banner Section -->
	<div *ngIf="!services?.hasSections">
	<!-- Service Banner Section -->
    <div class="service-banner-section" data-bg-image="assets/images/background/service-bg.jpg">
		<div class="auto-container">
			<div class="content-box">
				<h2><span>{{services?.title}}</span></h2>
				<div class="text">{{services?.description}}</div>
				<!-- <a href="javascript:void(0)" class="theme-btn btn-style-one" (click)="onGoToDetail()"><span class="txt">read more</span></a> -->
				
				<!-- Lower Box -->
				<div class="lower-box clearfix">
					<div class="pull-left">
						<div class="book">
							<span class="icon icofont-phone"></span>
							book through <br> call now
						</div>
					</div>
					<div class="pull-right">
						<a class="phone" href="tel:8688062159">+91 - 8688062159</a>
					</div>
				</div>
				
			</div>
			
		</div>
	</div>
	<!-- End Service Banner Section -->
	<section>
		<div class="services-section section-padding reputation-section-two style-two">
					<div class="auto-container">
						<div class="row clearfix">
							<div class="col-lg-7">
								<div class="sec-title">
									<div class="title">Documents Required</div>
									<h2><span>Sloving Business</span> For Your Business</h2>
									<p>Capitalise on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional base clickthroughs.</p>
								</div>
								<div class="row" *ngFor="let documents of services?.DocumentsRequired">
										<div class="col-md-8">
										<ul class="check-list">
											<li><i class="icofont-check"></i>{{documents.name}}</li>
											<!-- <li> <i class="icofont-check"></i>Shoulders</li>
											<li> <i class="icofont-check"></i>Knees</li>
											<li><i class="icofont-check"></i>Toes</li> -->
										  </ul>
										</div>
										<!-- <div class="col-md-6">
											<ul class="check-list">
												<li><i class="icofont-check"></i>Head</li>
												<li> <i class="icofont-check"></i>Shoulders</li>
												<li> <i class="icofont-check"></i>Knees</li>
												<li><i class="icofont-check"></i>Toes</li>
											  </ul>
											</div> -->
									
								</div>
							</div>
							<!-- Form Column -->
				<div class="form-column col-lg-5 col-md-12 col-sm-12">
					<div class="inner-column">
						<div class="form-boxed">
							<h5>free consulation</h5>
							
							<div class="consult-form">
								<form [formGroup]="userForm">

									<!--Form Group-->
									<div class="form-group">
										<label>full name</label>
										<input type="text" name="name" value="" placeholder="Your Name" formControlName="userName">
									</div>
									<!--Form Group-->
									<div class="form-group">
										<label>phone number</label>
										<input type="text" name="phone" value="" placeholder="Enter your phone number"  formControlName="userMobile">
									</div>
										<!--Form Group-->
										<div class="form-group">
											<label>Email</label>
											<input type="text" name="email" value="" placeholder="Enter your Email"  formControlName="userEmail">
										</div>
									<div>
										<label>
										  <!-- <input type="checkbox" formControlName="userTerms"> -->
										   Before Proceeding, you agree to the <a data-toggle="modal" 
										  data-target="#terms-condition" [routerLink]="['/termsandconditions']">Terms and Conditions</a>
										</label>
									</div>
									<div class="text-center mt-4 mb-4">
										<button class=" btn-style-one" type="submit" name="submit-form" (click)="submitUser()" [disabled]="!userForm.valid"><span class="txt">submit</span></button>
									</div>
								</form>
							
							</div>
						</div>
						
					</div>
				</div>
						</div>
						
				</div>
			</div>
	</section>
	
	
	<section class="pricing-section">
		<div class="sec-title text-center">
			<h2>Choose your package</h2>
		</div>
        <div class="container">
            <div class="outer-box">
                <div class="row">
                    <!-- Pricing Block -->
                    <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" *ngFor = "let package of services?.packageTypes">
                        <div class="inner-box">
                            <!-- <div class="icon-box">
                                <div class="icon-outer"><i class="fas fa-paper-plane"></i></div>
                            </div> -->
                            <div class="price-box">
                                <div class="title">{{package.name}}</div>
                                <!-- <h4 class="price">{{package.amount}}</h4> -->
                            </div>
                            <ul class="features">
                                <li class="true" *ngFor="let item of package?.includes">{{item}}</li>
                            </ul>
                            <div class="btn-box"  data-toggle="modal" data-target="#exampleModalCenter">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#exampleModalCenter" class="theme-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
	</div>
	<!-- Reputation Section Two -->
	<div class="reputation-section-two style-two pt-5">
		<div class="auto-container">
			<div class="row clearfix">
				
				<!-- Form Column -->
				<div class="form-column col-lg-5 col-md-12 col-sm-12">
					<div class="inner-column">
						<div class="form-boxed">
							<h5>free consulation</h5>
							
							<div class="consult-form">
								<form method="post" >

									<!--Form Group-->
									<div class="form-group">
										<label>full name</label>
										<input type="text" name="name" value="" placeholder="Enter your name">
									</div>
									<!--Form Group-->
									<div class="form-group">
										<label>Email</label>
										<input type="text" name="phone" value="" placeholder="Enter your Email" required>
									</div>
									<!--Form Group-->
									<div class="form-group">
										<label>Contact Number</label>
										<input type="text" name="phone" value="" placeholder="Enter your phone number" required>
									</div>
									<!--Form Group-->
									<div class="form-group">
										<label>message</label>
										<textarea name="message" placeholder="Write your message here"></textarea>
									</div>
									<div class="form-group">
										<button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="txt">SUBMIT</span></button>
									</div>
								</form>
							
							</div>
						</div>
						
					</div>
				</div>
				
				<!-- Content Column -->
				<div class="content-column col-lg-7 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="sec-title">
                            <div class="title">why choose us</div>
                            <h2><span>Your Success </span>is Our Reputation</h2>
                        </div>
                        <div class="blocks-outer">

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Affordable Pricing</h5>
                                    <div class="text">We believe in making professional ITR filing services accessible to all. Our pricing is designed to fit every budget, ensuring that no one is left behind in fulfilling their tax obligations</div>
                                </div>
                            </div>

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Expertise and Experience</h5>
                                    <div class="text">Our team of experienced tax professionals is well-versed in the intricate nuances of Indian tax laws and regulations. We stay up-to-date with the latest changes, ensuring that your returns are always accurate and compliant.
                                    </div>
                                </div>
                            </div>

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Timely Filing and Error-Free Returns</h5>
                                    <div class="text">Avoid the hassles of last-minute filing and the risk of errors. We ensure your returns are submitted well before the deadlines, eliminating the risk of late penalties and interest charges. Trust us for ITR filing that's accurate and efficient.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
				
			</div>
		</div>
	</div>
	<!-- End Reputation Section -->

	
  <!-- Services Section -->
  <div class="services-section">
	<div class="auto-container">
		<div class="sec-title">
			<div class="title">our services</div>
			<h2><span>Best Solutions</span> For Your Business</h2>
		</div>
  
		<div class="inner-container">
			<div class="row g-0">

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-blackboard"></span>
						</div>
						<h5><a (click)="onUpdateServices('IncomeTaxFiling')">Income tax return filing</a></h5>
						<!-- <div class="text">Thought leadership pass the clap hackathon wearables.</div> -->
						<a class="read-more" (click)="onUpdateServices('IncomeTaxFiling')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-stats-up"></span>
						</div>
						<h5><a (click)="onUpdateServices('GSTServices')">GST return filing</a></h5>
						<!-- <div class="text">Platform omnichannel click thought leadership pivot</div> -->
						<a class="read-more" (click)="onUpdateServices('GSTServices')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-shield"></span>
						</div>
						<h5><a (click)="onUpdateServices('GSTAnnualReturn')">GST Annual Return</a></h5>
						<!-- <div class="text">Disrupt taste makers help council emerging.</div> -->
						<a class="read-more" (click)="onUpdateServices('GSTAnnualReturn')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-home"></span>
						</div>
						<h5><a (click)="onUpdateServices('')">TDS return filing</a></h5>
						<!-- <div class="text">Mobile ready proprietary dedication intuitive</div> -->
						<a class="read-more" (click)="onUpdateServices('')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-stamp"></span>
						</div>
						<h5><a (click)="onUpdateServices('GSTRegistration')">GST registration</a></h5>
						<!-- <div class="text">Thought leadership pass the clap hackathon wearables.</div> -->
						<a class="read-more" (click)="onUpdateServices('GSTRegistration')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-money"></span>
						</div>
						<h5><a (click)="onUpdateServices('')">Payroll laws registration</a></h5>
						<!-- <div class="text">Disrupt taste makers help council emerging.</div> -->
						<a class="read-more" (click)="onUpdateServices('')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-shopping-cart-full"></span>
						</div>
						<h5><a (click)="onUpdateServices('')">Labour law registrations</a></h5>
						<!-- <div class="text">Mobile ready proprietary dedication intuitive</div> -->
						<a class="read-more" (click)="onUpdateServices('')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

				<!-- Service Block -->
				<div class="service-block col-lg-3 col-md-6 col-sm-12">
					<div class="inner-box">
						<div class="icon-box">
							<span class="icon ti-truck"></span>
						</div>
						<h5><a (click)="onUpdateServices('')">Other Services</a></h5>
						<!-- <div class="text">Platform omnichannel click thought leadership pivot</div> -->
						<a class="read-more" (click)="onUpdateServices('')">More <span
								class="ti-angle-right"></span></a>
					</div>
				</div>

			</div>

		</div>
	</div>
</div>
<!-- End Services Section -->

	
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" id="exampleModalLongTitle">Contact us for Expert Advise</h5>
		  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div class="modal-body">
			<div class="form-column col-lg-12gw col-md-12 col-sm-12">
				<!-- <div >
					<div> -->
						<!-- <h5>Contact us for Expert Advise</h5> -->
						
						<div class="consult-form">
							<form [formGroup]="userForm">


								<!--Form Group-->
								<div class="form-group">
									<label>full name</label>
									<input type="text" name="name" value="" placeholder="Enter your name" formControlName="userName">
								</div>
								<!--Form Group-->
								<div class="form-group">
									<label>Email</label>
									<input type="text" name="phone" value="" placeholder="Enter your Email" formControlName="userMobile" required>
								</div>
								<!--Form Group-->
								<div class="form-group" style="margin-left: 0px; margin-right: 0px;">
									<label>Contact Number</label>
									<input type="text" name="phone" value="" placeholder="Enter your phone number" formControlName="userEmail" required>
								</div>
								<!--Form Group-->
								<!-- <div class="form-group">
									<label>message</label>
									<textarea name="message" placeholder="Write your message here"></textarea>
								</div> -->
								<!-- <div class="form-group">
									<button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="txt">SUBMIT</span></button>
								</div> -->
							</form>
						
						</div>
					<!-- </div>
					
				</div> -->
			</div>
		</div>
		<div class="modal-footer">
		  <button type="button" class="theme-btn btn-style-one" data-dismiss="modal">Close</button>
		  <button type="button" class="theme-btn btn-style-one" (click)="submitUser()">Submit</button>
		</div>
	  </div>
	</div>
  </div>



  <div aria-live="polite" aria-atomic="true" style="position: relative; min-height: 200px;" *ngIf="showToastMessage">
	<div class="toast" style="position: absolute; top: 0; right: 0;">
	  <div class="toast-header">
		<img src="..." class="rounded mr-2" alt="...">
		<strong class="mr-auto">Success</strong>
		<!-- <small>11 mins ago</small> -->
		<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	  </div>
	  <div class="toast-body">
		Thanks for keeping trust on us. our team will get back to you soon ....
	  </div>
	</div>
  </div>
</div>


