	<!-- Page Banner Image Section -->
    <div class="page-banner-image-section about-banner-section">
        <div class="image">
            <img src="../../../assets/images/background/1.jpg" alt="" />
        </div>
    </div>
    <!-- End Page Banner Image Section -->
    <div class="container row about-banner-2">
    <div class="col-md-5  text-center">
        <div class="left">
        <h2>We strongly believe that
        best professional services
        should be available to
        everyone .</h2>
        </div>
        </div>
        <div class="col-md-5">
            <h4>This belief drives us to do what we do to win your taxes.</h4>
            <p> We pride ourself on saying we are “ India ka Tax Expert”.<br class="hidden-xs hidden-sm">
            We not just take care of our client's taxation needs but are committed to our mission of educating the masses about taxes to end their fear associated with taxes to make a winning experience for all. </p>
            </div>
    </div>

       <!-- About Section -->
       <div class="about-section">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row align-items-center clearfix">
                    <!-- Image Column -->
                    <div class="image-column col-lg-6">
                        <div class="about-image">
                            <div class="about-inner-image">
                                <img src="../../../assets/images/about/home-about.png" alt="about">
                            </div>
                        </div>
                    </div>

                    <!-- Content Column -->
                    <div class="content-column col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div class="about-column">
                            <div class="sec-title">
                                <div class="title">about us</div>
                                <h2><span>Tax Compliance simplified</span></h2>
                            </div>
                            <div class="text">
                                <p>We have 11 years of experience in helping individuals and businesses of various
                                    entities such as Proprietors, Firms, Companies. <br>
                                    Having recognized your business requirements, we will steer your business in the
                                    right strategic direction, solve problems, and implement changes. we've got your
                                    business goals and objectives in mind and inform you of opportunities to make
                                    business more profitable.
                                </p>
                                <p>you can trust us to take care of your financial needs accurately.</p>
                            </div>
                            <div class="signature">Daniel <span>Daniel Jr</span></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End About Section -->
    <div class="about-features">
        <div class="container row">
            <div class="col-md-12">
                <h2 class="text-center">What sets us apart?</h2>
                <h4 class="text-center"> There are reasons that people come to us for help.</h4>
                </div>
        </div>
        <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                <figure> <img src="https://tax2win.in/assets-new/img/about-us/we-care.svg" alt=""> </figure>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                <article> <span>We Care:</span>
                <p>Nothing is more important to us than the fact you get the benefit of every tax provision you deserve.</p>
                </article>
                </div>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                <figure> <img src="https://tax2win.in/assets-new/img/about-us/we-care.svg" alt=""> </figure>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                <article> <span>We Care:</span>
                <p>Nothing is more important to us than the fact you get the benefit of every tax provision you deserve.</p>
                </article>
                </div>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                <figure> <img src="https://tax2win.in/assets-new/img/about-us/we-care.svg" alt=""> </figure>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                <article> <span>We Care:</span>
                <p>Nothing is more important to us than the fact you get the benefit of every tax provision you deserve.</p>
                </article>
                </div>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                <figure> <img src="https://tax2win.in/assets-new/img/about-us/we-care.svg" alt=""> </figure>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                <article> <span>We Care:</span>
                <p>Nothing is more important to us than the fact you get the benefit of every tax provision you deserve.</p>
                </article>
                </div>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                <figure> <img src="https://tax2win.in/assets-new/img/about-us/we-care.svg" alt=""> </figure>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                <article> <span>We Care:</span>
                <p>Nothing is more important to us than the fact you get the benefit of every tax provision you deserve.</p>
                </article>
                </div>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                <figure> <img src="https://tax2win.in/assets-new/img/about-us/we-care.svg" alt=""> </figure>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9">
                <article> <span>We Care:</span>
                <p>Nothing is more important to us than the fact you get the benefit of every tax provision you deserve.</p>
                </article>
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>

    <!-- <section class="brands-logo">
        <div class="container">
        <h2><strong>Brands</strong> Empowered</h2>
        <hr class="hr">
        <ul class="clearfix">
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/sbi-logo.png" alt="SBI Bank" title="SBI Bank" width="135" height="25"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/icici-logo.png" alt="ICICI Bank" title="ICICI Bank" width="135" height="26"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/indusind-bank-logo.png" alt="Indusind Bank" title="Indusind Bank" width="135" height="27"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/life99.png" alt="Life99" title="Life99" width="110" height="38"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/zinghr-logo.png" alt="ZingHr" title="ZingHr" width="125" height="56"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/bajaj-finserv-logo.png" alt="Bajaj Finserv" title="Bajaj Finserv" width="135" height="36"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/g-pay-logo.png" alt="Google Pay" title="Google Pay" width="100" height="40"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/phone-pay.png" alt="Phone Pay" title="Phone Pay" width="135" height="36"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/paytm.png" alt="Paytm" title="Paytm" width="125" height="35"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/icici-securities.png" alt="ICICI Securities" title="ICICI Securities" width="130" height="32"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/darwinbox-logo.png" alt="Darwinbox" title="Darwinbox" width="150" height="33"></li>
        <li><img src="https://tax2win.in/assets-new/img/clients-logos/benefitHub_logo.png" alt="Benefit Hub" title="Benefit Hub" width="135" height="28"></li>
        </ul>
        </div>
        </section> -->

    <!-- Reputation Section Two -->
    <div class="reputation-section-two">
        <div class="auto-container">
            <div class="row clearfix">

                <!-- Content Column -->
                <div class="content-column col-lg-7 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="sec-title">
                            <div class="title">why choose us</div>
                            <h2><span>Your Success </span>is Our Reputation</h2>
                        </div>
                        <div class="blocks-outer">

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Affordable Pricing</h5>
                                    <div class="text">We believe in making professional ITR filing services accessible to all. Our pricing is designed to fit every budget, ensuring that no one is left behind in fulfilling their tax obligations</div>
                                </div>
                            </div>

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Expertise and Experience</h5>
                                    <div class="text">Our team of experienced tax professionals is well-versed in the intricate nuances of Indian tax laws and regulations. We stay up-to-date with the latest changes, ensuring that your returns are always accurate and compliant.
                                    </div>
                                </div>
                            </div>

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Timely Filing and Error-Free Returns</h5>
                                    <div class="text">Avoid the hassles of last-minute filing and the risk of errors. We ensure your returns are submitted well before the deadlines, eliminating the risk of late penalties and interest charges. Trust us for ITR filing that's accurate and efficient.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Form Column -->
                <div class="form-column col-lg-5 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="form-boxed">
                            <h5>free consulation</h5>

                            <div class="consult-form">
                                <form [formGroup]="userForm">

                                    <!--Form Group-->
                                    <div class="form-group">
                                        <label>full name</label>
                                        <input type="text" name="name" value="" placeholder="Enter your name"  formControlName="userName">
                                    </div>
                                    <!--Form Group-->
                                    <div class="form-group">
                                        <label>phone number</label>
                                        <input type="text" name="phone" value=""
                                            placeholder="Enter your phone number"  formControlName="userMobile">
                                    </div>
                                    <!--Form Group-->
                                    <!-- <div class="form-group">
                                        <label>topics</label>
                                        <select class="custom-select-box">
                                            <option>Choose topic</option>
                                            <option>Topic 01</option>
                                            <option>Topic 02</option>
                                            <option>Topic 03</option>
                                            <option>Topic 04</option>
                                        </select>
                                    </div> -->
                                    <!--Form Group-->
                                    <div class="form-group">
                                        <label>message</label>
                                        <textarea name="message" placeholder="Write your message here"  formControlName="userMessage"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button class="theme-btn btn-style-one" type="submit"
                                            name="submit-form"><span class="txt"  (click)="submitUser()">SUBMIT</span></button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- End Reputation Section -->

    <!-- Testimonial Section -->
    <div class="testimonial-section style-two">
        <div class="auto-container">
            <div class="inner-container">

                <!-- Sec Title -->
                <div class="sec-title">
                    <div class="title">testimonials</div>
                    <h2><span>+2,500 Clients </span>Love Us</h2>
                </div>
                <div class="testimonial-carousel owl-carousel owl-theme">

                    <!-- Testimonial Block -->
                    <div class="testimonial-block">
                        <div class="inner-box">
                            <div class="quote icofont-quote-right"></div>
                            <div class="author">Ryan Betthalyn <span>/ Director at Chobham Manor</span></div>
                            <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                                magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.
                            </div>
                        </div>
                    </div>

                    <!-- Testimonial Block -->
                    <div class="testimonial-block">
                        <div class="inner-box">
                            <div class="quote icofont-quote-right"></div>
                            <div class="author">Bobs Hanley <span>/ Director at Spotify</span></div>
                            <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                                magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.
                            </div>
                        </div>
                    </div>

                    <!-- Testimonial Block -->
                    <div class="testimonial-block">
                        <div class="inner-box">
                            <div class="quote icofont-quote-right"></div>
                            <div class="author">Ryan Betthalyn <span>/ Director at Chobham Manor</span></div>
                            <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                                magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.
                            </div>
                        </div>
                    </div>

                    <!-- Testimonial Block -->
                    <div class="testimonial-block">
                        <div class="inner-box">
                            <div class="quote icofont-quote-right"></div>
                            <div class="author">Bobs Hanley <span>/ Director at Spotify</span></div>
                            <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                                magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- End Testimonial Section -->

    <!-- Sponsors Section -->
    <div class="sponsors-section">
        <div class="auto-container">

            <div class="carousel-outer">
                <!--Sponsors Slider-->
                <ul class="sponsors-carousel owl-carousel owl-theme">
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/1.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/2.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/3.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/4.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/5.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/1.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/2.png" alt=""></a></div>
                    </li>
                    <li>
                        <div class="image-box"><a href="#"><img src="../../../assets/images/clients/3.png" alt=""></a></div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
    <!--End Sponsors Section-->