    <div class="bg-service1-img">
        <div class="container">
            <div class="service-message">
                <h1>{{services?.title}}</h1>
                <ul>
                    <li><a href="javascript:void(0)" [routerLink]="['/home']" >Home</a></li>
                    <li>{{services?.title}}</li>
                </ul>
            </div>
        </div>
    </div>
    <section class="service-section-padding">
        <div class="wpb_wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="title-section">
                            <h2 class="title">{{services?.title}}</h2>
                        </div>
                        <div class="section-heading-line-left"><br></div>
                        <p><span  [innerHtml]="services?.description"></span></p>
                    </div>
                    <div class="col-md-6">
                        <h2><img src="https://www.myaccountsconsultant.com/assets_old/images/page/Accounts-Payables-Services-%20(1).jpg" alt=" Accounts Payable Services" style="display: block; margin-left: auto; margin-right: auto; margin-top: 10px;" class="img-responsive img-rounded"
                                width="1000" height="600"></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="wrapper">
        <div class="container-fostrap">

            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6" *ngIf="services?.DocumentsRequired">
                            <div class="card">
                                <a class="img-card" href="#">
                                    <img src="https://www.myaccountsconsultant.com/assets/images/page/Sign-contract.jpg" />
                                </a>
                                <div class="card-content" >
                                    <h4 class="card-title pl-3">
                                        <a> Documents Required
                                      </a>
                                    </h4>
                                    <ul class="list-group"  *ngIf="services?.isGST === false;else GST_Reg;">
                                        <li class="list-group-item border-0 d-flex" *ngFor="let documents of services.DocumentsRequired">
                                            <div class="me-2">
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZyjSITET-RHh0uN_gXgEwtdvzqJLaqhG6bA&usqp=CAU" alt="">
                                            </div>
                                            <div class="">
                                                {{documents.name}}
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-template #GST_Reg>
                                        <div *ngFor="let documents of services.DocumentsRequired.categories">
                                            <p>{{documents.categoryName}}</p>
                                         <ul>
                                            <li class="list-group-item border-0 d-flex" *ngFor="let doc of documents.documentsRequired">
                                                <div class="me-2">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZyjSITET-RHh0uN_gXgEwtdvzqJLaqhG6bA&usqp=CAU" alt="">
                                                </div>
                                                <div class="">
                                                    {{doc}}
                                                </div>
                                            </li>
                                         </ul>
                                        </div>
                                    </ng-template>
                                </div>
                                <!-- <div class="card-read-more">
                                    <a href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html" class="btn btn-link btn-block">
                                        Read More
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6" *ngIf="services?.Amount">
                            <div class="card">

                                <div class="card-content">
                                    <h4 class="card-title">
                                        Sign Up
                                    </h4>
                                    <h3 style="font-size: 50px;color: #16181e;text-align: center;font-weight: 700;"> Rs.{{services?.Amount}}</h3>
                                    <form role="form" class="mt-4" [formGroup]="userForm">

                                        <div class="form-group">
                                            <input type="text" class="form-control input-lg" placeholder="Your Name" formControlName="userName">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control input-lg" placeholder="Email Address" formControlName="userEmail">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control input-lg" placeholder="Phone Number" formControlName="userMobile">
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                              <input type="checkbox" formControlName="userTerms"> Before Proceeding, you agree to the <a data-toggle="modal" 
                                              data-target="#terms-condition" [routerLink]="['/termsandconditions']">Terms and Conditions</a>
                                            </label>
                                        </div>
                                        <div class="row">
                                            <div class="mt-2"><input type="submit" value="Proceed" class=" submit-form-btn btn btn-primary btn-block btn-lg" [disabled]="userForm.invalid || userForm.value.userTerms === false"
                                                 (click)="submitUser()"></div>

                                        </div>
                                    </form>
                                </div>
                                <!-- <div class="card-read-more">
                                    <a href="https://codepen.io/wisnust10/full/ZWERZK/" class="btn btn-link btn-block">
                                        Read More
                                    </a>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>