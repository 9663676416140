<!-- <div class="bg-img">

    <div class="wrapper container">
        <div class="message">
            <h1>Online Accounting Services</h1>
            <p>Fast, reliable and accurate online accounting service helps to</p>
            <p> make better financial decisions.</p>
            <h3>Quality services at feasible price.</h3>
        </div>
    </div>
</div> -->
<carousel [objectFit]="'cover'" [cellWidth]="'100%'" [autoplay]="true" [images]="images" [dots]="true" [height]="500"
    [loop]="true" [overflowCellsLimit]="1" style=" background-color: #999;position:relative;">
</carousel>

<section class="service-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h2>{{content.About.title}}</h2>
                <div class="text" [innerHtml]="content.About.description">

                </div>
            </div>
            <div class="col-lg-6">
                <div>
                    <img src="../../../assets/images/itr-home.webp" class="img-responsive">
                </div>
            </div>

        </div>
        <div class="row mt-4">
            <div class="col-md-4" *ngFor="let service of content.services.servicesList">
                <div class="need-help">
                    <div class="header-div text-vision">
                        <a href="javascript:void(0)" class="main_headingbox"
                            (click)="onUpdateServices(service)">{{service.title}}</a>
                        <div class="sub_headingbox">{{service.description}}</div>
                    </div>
                    <button type="button" class="btn btn-primary mt-4">
                        <div class="usetools_text">Click here to File</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="choose">
    <div class="text-center service-header">
        <h3>{{content.newBusiness.title}}</h3>
        <p>{{content.newBusiness.description}}</p>
    </div>
    <div class="container mt-4">
        <div class="ml-0 mr-0">
            <div class="row col-md-6 col-sm-12 pt-4" *ngFor="let service of content.newBusiness.servicesList">
                <div class="">
                    <div class="icon-box">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/bookkeeping.png" width="60"
                            height="60">
                    </div>
                    <div class="text-vision">
                        <h4>
                            <a href="javascript:void(0)" (click)="readMore(service)">{{service.title}}</a>
                        </h4>
                        <p>{{service.description}}
                        </p>
                        <a href="javascript:void(0)" (click)="readMore(service)">{{service.readMore}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container mt-4">
        <div class="row ml-0 mr-0">
            <div class=" col-lg-4 col-md-6 col-sm-12">
                <div class="">
                    <div class="icon-box">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/bookkeeping.png" width="60" height="60">
                    </div>
                    <div class="text-vision">

                        <h4>New Business</h4>
                        <p>A sole proprietor is the simplest form of an organization. It is very easy and convenient way of doing business. It is the most common form of business in India. It has very minimum registrations and have very minimal regulatory compliance requirement. However, after the business develops, proprietorship does not offer facilities...
                        </p>
                        <a href="">Read more</a>
                    </div>
                </div>
            </div>
            <div class=" col-lg-4 col-md-6 col-sm-12">
                <div class="">
                    <div class="icon-box">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/bookkeeping.png" width="60" height="60">
                    </div>
                    <div class="text-vision">

                        <h4>TDS Preparation</h4>
                        <p>TDS Filing Service requires thorough understanding of the tax provisions, where EZTax.in Experts can help you file your TDS Returns with ease. EZTax.in help you in filing the Sale on Property (From-26QB), TDS on Salary (From-24Q), Other payments (Form-26Q) with best & lowest Pricing.
                        </p>
                        <a href="">Read more</a>
                    </div>
                </div>
            </div>
            <div class=" col-lg-4 col-md-6 col-sm-12">
                <div class="">
                    <div class="icon-box">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/bookkeeping.png" width="60" height="60">
                    </div>
                    <div class="text-vision">

                        <h4>Intellectual Property</h4>
                        <p>To provide reliable professional services with integrity, excellence and confidentiality in the system of our firm to meet our customer's individual requests.
                            <p>
                        </p>
                        <a href="">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>

<section class="service-section">
    <div class="container">
        <div class="text-center">
            <h3 class="choose-h3">Why choose us?</h3>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="choose-card">
                    <div class="choose_inner">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/Software-Migration.png"
                            width="80px">
                        <h4 class="choose-header ">Vision</h4>
                        <div class="choose-para">
                            <p>
                                Our top most prority is to keep your data safe and secure with us. We regiously work
                                towards providing the best services in a short span of time.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="choose-card">
                    <div class="choose_inner">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/Software-Migration.png"
                            width="80px">
                        <h4 class="choose-header ">Vision</h4>
                        <div class="choose-para">
                            <p>
                                Our top most prority is to keep your data safe and secure with us. We regiously work
                                towards providing the best services in a short span of time.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="choose-card">
                    <div class="choose_inner">
                        <img src="https://www.myaccountsconsultant.com/assets/images/logos/Software-Migration.png"
                            width="80px">
                        <h4 class="choose-header ">Vision</h4>
                        <div class="choose-para">
                            <p>
                                Our top most prority is to keep your data safe and secure with us. We regiously work
                                towards providing the best services in a short span of time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>