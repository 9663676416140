<div class="page-wrapper">
    <!-- <div class="banner-section">
        <div>
            <div class="image"> -->
                <!-- <img src="../../../assets/images/main-slider/banner1.jpg" alt="" /> -->
                <!-- <div class="content-column col-lg-7 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="title">Income Tax filing</div>
                        <h1>Get your Income-tax return filed by our <span>tax experts</span></h1>
                        <div class="text"><b>Data Security and confidentiality is our priority</b><br> Get
                            your
                            personal tax expert to do tax filing for you. And it is personal, convenient &
                            fast with maximum refund calculated for you. Let we handle Your total Tax &
                            Business Compliance.</div>
                        <div class="btn-box">
                            <a href="about.html" class="theme-btn btn-style-one"><span class="txt">Work With
                                    Us</span></a>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->

            <!-- <div class="slide" data-bg-image="images/main-slider/banner2.jpg">
                <div class="auto-container w-100">
                    <div class="row clearfix"> -->

                        <!-- Content Column -->
                        <!-- <div class="content-column col-lg-7 col-md-12 col-sm-12">
                            <div class="inner-column">
                                <div class="title">GST Return filing</div>
                                <h1>Get all your GST compliances return filed by our <span>tax experts</span></h1>
                                <div class="text"><b>Data Security and confidentiality is our priority</b><br>GST
                                    Filing Service Plans, Best & Lowest Pricing depends on the type of business like
                                    regular, composition, and the number of computerized transactions in your
                                    business per month. We provide expert assisted GST filing plans & pricing are
                                    affordable & customizable per SME businesses</div>
                                <div class="btn-box">
                                    <a href="about.html" class="theme-btn btn-style-one"><span class="txt">Work With
                                            Us</span></a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div> -->
<!-- 
            <div class="slide" data-bg-image="images/main-slider/banner3.jpg">
                <div class="auto-container w-100">
                    <div class="row clearfix">

                    
                        <div class="content-column col-lg-7 col-md-12 col-sm-12">
                            <div class="inner-column">
                                <div class="title">GST Registration</div>
                                <h1>Start your dream by obtaining Business <span>GST registration</span></h1>
                                <div class="text"><b>GST registration</b>We provide the New GST Registration by
                                    Online Document Uploading & Tracking. File your GST Application in 24 hours and
                                    Get Your TRN on same day. GST Registration and Follow-up is also provided.</div>
                                <div class="btn-box">
                                    <a href="about.html" class="theme-btn btn-style-one"><span class="txt">Work With
                                            Us</span></a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div> -->
        <!-- </div>
    </div> -->
    <!-- CTA Section Start -->
    <div class="cta-section" data-bg-image="../../../assets/images/gallery/2.jpg">
        <div class="auto-container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <!-- CTA Content Start -->
                    <div class="cta-content">
                        <h3 class="title">Book an appoitment quickly? <span class="text-bold">Call us now!</span>
                        </h3>
                        <p>We prodive a dedicated support 24/7 for any your question</p>
                    </div>
                    <!-- CTA Content End -->
                </div>
                <div class="col-lg-5">
                    <!-- CTA Phone Number Start -->
                    <div class="cta-phone text-lg-end text-strat">
                        <h2 class="title">+0962-58-58-258</h2>
                    </div>
                    <!-- CTA Phone Number Start -->
                </div>
            </div>
        </div>
    </div>
    <!-- CTA Section End -->

    <!-- About Section -->
    <div class="about-section">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row align-items-center clearfix">
                    <!-- Image Column -->
                    <div class="image-column col-lg-6">
                        <div class="about-image">
                            <div class="about-inner-image">
                                <img src="../../../assets/images/about/home-about.png" alt="about">
                            </div>
                        </div>
                    </div>

                    <!-- Content Column -->
                    <div class="content-column col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div class="about-column">
                            <div class="sec-title">
                                <div class="title">about us</div>
                                <h2><span>Tax Compliance simplified</span></h2>
                            </div>
                            <div class="text">
                                <p>We have 11 years of experience in helping individuals and businesses of various
                                    entities such as Proprietors, Firms, Companies. <br>
                                    Having recognized your business requirements, we will steer your business in the
                                    right strategic direction, solve problems, and implement changes. we've got your
                                    business goals and objectives in mind and inform you of opportunities to make
                                    business more profitable.
                                </p>
                                <p>you can trust us to take care of your financial needs accurately.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End About Section -->

    <!-- Services Section -->
    <div class="services-section">
        <div class="auto-container">
            <div class="sec-title">
                <div class="title">our services</div>
                <h2><span>Best Solutions</span> For Your Business</h2>
            </div>
      
            <div class="inner-container">
                <div class="row g-0">

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-blackboard"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('IncomeTaxFiling')">Income tax return filing</a></h5>
                            <!-- <div class="text">Thought leadership pass the clap hackathon wearables.</div> -->
                            <a class="read-more" (click)="onUpdateServices('IncomeTaxFiling')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-stats-up"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('GSTServices')">GST return filing</a></h5>
                            <!-- <div class="text">Platform omnichannel click thought leadership pivot</div> -->
                            <a class="read-more" (click)="onUpdateServices('GSTServices')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-shield"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('GSTAnnualReturn')">GST Annual Return</a></h5>
                            <!-- <div class="text">Disrupt taste makers help council emerging.</div> -->
                            <a class="read-more" (click)="onUpdateServices('GSTAnnualReturn')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-home"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('')">TDS return filing</a></h5>
                            <!-- <div class="text">Mobile ready proprietary dedication intuitive</div> -->
                            <a class="read-more" (click)="onUpdateServices('')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-stamp"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('GSTRegistration')">GST registration</a></h5>
                            <!-- <div class="text">Thought leadership pass the clap hackathon wearables.</div> -->
                            <a class="read-more" (click)="onUpdateServices('GSTRegistration')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-money"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('')">Payroll laws registration</a></h5>
                            <!-- <div class="text">Disrupt taste makers help council emerging.</div> -->
                            <a class="read-more" (click)="onUpdateServices('')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-shopping-cart-full"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('')">Labour law registrations</a></h5>
                            <!-- <div class="text">Mobile ready proprietary dedication intuitive</div> -->
                            <a class="read-more" (click)="onUpdateServices('')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                    <!-- Service Block -->
                    <div class="service-block col-lg-3 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="icon-box">
                                <span class="icon ti-truck"></span>
                            </div>
                            <h5><a (click)="onUpdateServices('')">Other Services</a></h5>
                            <!-- <div class="text">Platform omnichannel click thought leadership pivot</div> -->
                            <a class="read-more" (click)="onUpdateServices('')">More <span
                                    class="ti-angle-right"></span></a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- End Services Section -->

    <!-- Project Section -->
    <div class="project-section">
        <div class="auto-container">

            <!-- Sec Title -->
            <div class="sec-title">
                <div class="clearfix">
                    <div class="pull-left">
                        <!-- <div class="title">latest projects</div> -->
                        <h2>Start a <span>Successful Businesses</span></h2>
                    </div>
                    <!-- <div class="pull-right">
                        <a href="service.html" class="cases">all Cases <span
                                class="arrow ti-angle-right"></span></a>
                    </div> -->
                </div>
            </div>

        </div>
        <div class="outer-container">
            <div class="row clearfix">

                <!-- Column -->
                <div class="column col-lg-6 col-md-12 col-sm-12">
                    <div class="row clearfix">

                        <!-- Inner Column -->
                        <div class="inner-column col-lg-6 col-md-6 col-sm-12">
                            <div class="gallery-block">
                                <div class="inner-box">
                                    <div class="image">
                                        <img src="../../../assets/images/gallery/2.jpg" alt="" />
                                        <div class="overlay-box">
                                            <div class="overlay-inner">
                                                <h3><a href="javascript:void(0)" (click)="onUpdateServices('proprietorShip')">Sole Proprietor</a></h3>
                                                <div class="designation">Finance, Consulting</div>
                                                <a href="javascript:void(0)" (click)="onUpdateServices('proprietorShip')" class="arrow ti-angle-right"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Inner Column -->
                        <div class="inner-column col-lg-6 col-md-6 col-sm-12">
                            <div class="gallery-block">
                                <div class="inner-box">
                                    <div class="image">
                                        <img src="../../../assets/images/gallery/3.jpg" alt="" />
                                        <div class="overlay-box">
                                            <div class="overlay-inner">
                                                <h3><a href="javascript:void(0)" (click)="onUpdateServices('partnerShip')">Partnership firm</a></h3>
                                                <div class="designation">Finance, Consulting</div>
                                                <a href="javascript:void(0)" (click)="onUpdateServices('partnerShip')" class="arrow ti-angle-right"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Inner Column -->
                        <div class="inner-column col-lg-12 col-md-12 col-sm-12">
                            <div class="gallery-block">
                                <div class="inner-box">
                                    <div class="image">
                                        <img src="../../../assets/images/gallery/4.jpg" alt="" />
                                        <div class="overlay-box">
                                            <div class="overlay-inner">
                                                <h3><a href="javascript:void(0)"  (click)="
                                                    onUpdateServices('LimitedLiabilityPartnership')
                                                  ">Limited Liability Partnership</a></h3>
                                                <div class="designation">Finance, Consulting</div>
                                                <a href="javascript:void(0)"  (click)="
                                                onUpdateServices('LimitedLiabilityPartnership')
                                              " class="arrow ti-angle-right"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Column -->
                <div class="column col-lg-6 col-md-12 col-sm-12">
                    <div class="gallery-block">
                        <div class="inner-box">
                            <div class="image">
                                <img src="../../../assets/images/gallery/1.jpg" alt="" />
                                <div class="overlay-box">
                                    <div class="overlay-inner">
                                        <h3><a href="javascript:void(0)" (click)="onUpdateServices('privateLimitedCompany')">Private limited company</a></h3>
                                        <div class="designation">Finance, Consulting</div>
                                        <a href="javascript:void(0)" (click)="onUpdateServices('privateLimitedCompany')" class="arrow ti-angle-right"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- End Project Section -->

    <!-- Testimonial Section -->
    <!-- <div class="testimonial-section">
        <div class="auto-container"> -->
            <!-- Sec Title -->
            <!-- <div class="sec-title">
                <div class="title">testimonials</div>
                <h2><span>+2,500 Clients </span>Love Us</h2>
            </div>
            <div class="testimonial-carousel owl-carousel owl-theme"> -->

                <!-- Testimonial Block -->
                <!-- <div class="testimonial-block">
                    <div class="inner-box">
                        <div class="quote icofont-quote-right"></div>
                        <div class="author">Ryan Betthalyn <span>/ Director at Chobham Manor</span></div>
                        <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                            magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.</div>
                    </div>
                </div> -->

                <!-- Testimonial Block -->
                <!-- <div class="testimonial-block">
                    <div class="inner-box">
                        <div class="quote icofont-quote-right"></div>
                        <div class="author">Bobs Hanley <span>/ Director at Spotify</span></div>
                        <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                            magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.</div>
                    </div>
                </div> -->

                <!-- Testimonial Block -->
                <!-- <div class="testimonial-block">
                    <div class="inner-box">
                        <div class="quote icofont-quote-right"></div>
                        <div class="author">Ryan Betthalyn <span>/ Director at Chobham Manor</span></div>
                        <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                            magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.</div>
                    </div>
                </div> -->

                <!-- Testimonial Block -->
                <!-- <div class="testimonial-block">
                    <div class="inner-box">
                        <div class="quote icofont-quote-right"></div>
                        <div class="author">Bobs Hanley <span>/ Director at Spotify</span></div>
                        <div class="text">Sed elit quam, iaculis sed semper sit amet udin vitae nibh. Rubino at
                            magna akal semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup.</div>
                    </div>
                </div> -->

            <!-- </div>
        </div>
    </div> -->
    <!-- End Testimonial Section -->


    <!-- Reputation Section Two -->
    <div class="reputation-section-two style-two">
        <div class="auto-container">
            <div class="row clearfix">

                <!-- Form Column -->
                <div class="form-column col-lg-5 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="form-boxed">
                            <h5>Free consultation</h5>

                            <div class="consult-form">
                                <form [formGroup]="userForm">

                                    <!--Form Group-->
                                    <div class="form-group">
                                        <label>full name</label>
                                        <input type="text" name="name" value="" placeholder="Enter your name"  formControlName="userName">
                                    </div>
                                    <!--Form Group-->
                                    <div class="form-group">
                                        <label>phone number</label>
                                        <input type="text" name="phone" value=""
                                            placeholder="Enter your phone number"  formControlName="userMobile">
                                    </div>
                                    <!--Form Group-->
                                    <!-- <div class="form-group">
                                        <label>topics</label>
                                        <select class="custom-select-box">
                                            <option>Choose topic</option>
                                            <option>Topic 01</option>
                                            <option>Topic 02</option>
                                            <option>Topic 03</option>
                                            <option>Topic 04</option>
                                        </select>
                                    </div> -->
                                    <!--Form Group-->
                                    <div class="form-group">
                                        <label>message</label>
                                        <textarea name="message" placeholder="Write your message here"  formControlName="userMessage"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button class="theme-btn btn-style-one" type="submit"
                                            name="submit-form"><span class="txt"  (click)="submitUser()">SUBMIT</span></button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>

                <!-- Content Column -->
                <div class="content-column col-lg-7 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="sec-title">
                            <div class="title">why choose us</div>
                            <h2><span>Your Success </span>is Our Reputation</h2>
                        </div>
                        <div class="blocks-outer">

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Affordable Pricing</h5>
                                    <div class="text">We believe in making professional ITR filing services accessible to all. Our pricing is designed to fit every budget, ensuring that no one is left behind in fulfilling their tax obligations</div>
                                </div>
                            </div>

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Expertise and Experience</h5>
                                    <div class="text">Our team of experienced tax professionals is well-versed in the intricate nuances of Indian tax laws and regulations. We stay up-to-date with the latest changes, ensuring that your returns are always accurate and compliant.
                                    </div>
                                </div>
                            </div>

                            <!-- Reputation Block -->
                            <div class="reputation-block">
                                <div class="inner-box">
                                    <h5>Timely Filing and Error-Free Returns</h5>
                                    <div class="text">Avoid the hassles of last-minute filing and the risk of errors. We ensure your returns are submitted well before the deadlines, eliminating the risk of late penalties and interest charges. Trust us for ITR filing that's accurate and efficient.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- End Reputation Section -->

    <!-- Experts Section -->

    <!-- End Experts Section -->

    <!-- Blog Section -->
    
    <!-- End Blog Section -->

    <!-- Map Section -->
    <div class="map-section">
        <div class="contact-map-area">
                <iframe  class="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3211.5409287974803!2d78.35920147171329!3d17.44675154687475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb935edaf15937%3A0x267296f5e3fd660e!2sAwfis%20Srestha%20Marvel%20Gachibowli!5e1!3m2!1sen!2sin!4v1714217979832!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    <!-- End Map Section -->
   

</div>
<!--End pagewrapper-->

<!-- Search Popup -->
<div class="search-popup">
    <button class="close-search style-two"><span class="icofont-brand-nexus"></span></button>
    <button class="close-search"><span class="icofont-arrow-up"></span></button>
    <form method="post" action="https://htmldemo.hasthemes.com/consultix/consultix/blog.html">
        <div class="form-group">
            <input type="search" name="search-field" value="" placeholder="Search Here" required="">
            <button type="submit"><i class="fa fa-search"></i></button>
        </div>
    </form>
</div>
<!-- End Header Search -->

